import React from 'react';
// import HyperLink from './HyperLink';
// import type { Link } from '../../types/Link';
// import { routes } from '../../constants/routes';

const Greeting = (): React.ReactElement => {
  // const projectsLink: Link = {
  //   url: `${routes.projects.path}/`,
  // };

  // const blogLink: Link = {
  //   url: `${routes.blog.path}/`,
  // };

  // const projectsLinkElement = (
  //   <div className="inline-block">
  //     <HyperLink link={projectsLink} className="underline font-semibold">projects</HyperLink>
  //   </div>
  // );

  // const blogLinkElement = (
  //   <div className="inline-block">
  //     <HyperLink link={blogLink} className="underline font-semibold">articles</HyperLink>
  //   </div>
  // );

  return (
    <>
      <p className="font-light">
        Hi there!
        I&apos;m Claudia, a Product Development Consultant at <a href="https://plural.com/" target="_blank" rel="noopener noreferrer">Plural.com</a>.
        <br /><br />

        I am committed to excellence and continuous improvement. I thrive on challenges and deliver high-quality results across e-commerce platforms, e-learning software, streaming services, SaaS, and mobile apps.
        Here are some of my key accomplishments:
        <br /><br />

        <ul>
          <li>
            🏆 <b>E-learning (Web + Mobile Apps) for MedFlash</b>
            <br />
            Earned a leadership position in medicine e-learning by innovating the UI, introducing media content, and executing a subscription business model, growing users by 140% in 2 years. <a href="/projects/medflash" target="_blank" rel="noopener noreferrer"><u>Read More</u></a>
          </li>
          <br />

          <li>
            🏆 <b>Streaming Service (Web + Mobile Apps) for Iupki</b>
            <br />
            Reached 20,000 monthly listeners in the first six months by partnering with schools and renowned artists, making it the national leader in media for children. <a href="/projects/iupki/" target="_blank" rel="noopener noreferrer"><u>Read More</u></a>
          </li>
          <br />

          <li>
            🏆  <b>SaaS E-commerce (Web + Mobile Apps) for Elo Farma Network</b>
            <br />
            Achieved an average NPS of 64 while complying with strict pharmaceutical legal frameworks. <a href="/projects/elo_farma" target="_blank" rel="noopener noreferrer"><u>Read More</u></a>
          </li>
          <br />

        </ul>
        To get insight into how I can help your business, check out my complete <a href="/projects" target="_blank" rel="noopener noreferrer"><b><u>portfolio</u></b></a>.
        <br /><br />


        <h3 className="font-bold uppercase">Professional Journey</h3>
        <ul>
          <li>
            - Product Development Consultant at Plural.com · Ongoing
          </li>
          <li>
            - Marketing Project Manage at Josefinas · 1 year
          </li>
          <li>
            - Agile Project Manager for Software Development Projects at BloomIdea · 5 years
          </li>
          <li>
            - Recruiter for Agile Development Teams at BloomIdea · 7 months
          </li>
          <li>
            - Sales Representative for Websites and Mobile Apps Development at BloomIdea · 3 months
          </li>
          <li>
            - Copywriter at C&P Blog · 4 years
          </li>
          <li>
            - Event Staff at Universidade do Minho · 7 months
          </li>
          <li>
            - Sales Representative at Avon · 5 months
          </li>
          <li>
            - Recruiter of Sales Representatives at Avon · 5 months
          </li>
        </ul>
        <br />

        <h3 className="font-bold uppercase">Academic Background</h3>
        <ul>
          <li>
            - B.B.A. in Marketing Research and Innovation Management at the University of Passau, Germany
          </li>
          <li>
            - B.B.A. in Finance, Strategy, and Marketing at the University of Minho, Portugal
          </li>
        </ul>
        <br />

        <h3 className="font-bold uppercase">Let&apos;s Connect</h3>
        Explore my projects and read my articles to see my work in action. Feel free to contact me to discuss potential collaborations or to learn more about my experience and skills. Check out my portfolio or <a href="https://cal.com/icfclaudia" target="_blank" rel="noopener noreferrer"><b><u>book a call</u></b></a>.

        {/* {projectsLinkElement} */}
        {/* {blogLinkElement} */}

      </p>
    </>
  );
};

export default Greeting;
